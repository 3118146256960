import React, { useEffect, useState } from "react";

import { smartUserSignIn } from "../SignInApi";
import Popup from "reactjs-popup";

import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from "react-router-dom";
import SmartSignInCss from "./SmartSignIn.module.css";
import MainLogoImage from "../images/navienSmart/logo.png";
import IdIcon from "../images/navienSmart/ico_login_id_mo.png";
import PwIcon from "../images/navienSmart/ico_login_pw_mo.png";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const SmartSignInPre = ({ history }) => {
  const location = useLocation();
  const [idState, setIdState] = useState("");
  const [passwordState, setPasswordState] = useState("");
  const [idResetBtnState, setIdResetBtnState] = useState(false);
  const [passwordShowBtnState, setPasswordShowBtnState] = useState(false);
  const [loginButtonState, setLoginButtonState] = useState(false);
  const [popupState, setPopupState] = useState(false);
  const [backdropLoading, setBackdropLoading] = useState(false);

  const classes = useStyles();
  const [queryString] = useState(location);

  useEffect(() => {
    if (idState.length > 0) {
      setIdResetBtnState(true);
    } else {
      setIdResetBtnState(false);
    }
    if (idState.length > 0 && passwordState.length > 0) {
      setLoginButtonState(true);
    } else {
      setLoginButtonState(false);
    }
  }, [idState, passwordState]);

  const loginButtonClick = async () => {
    setBackdropLoading(true);
    try {
      const response = await smartUserSignIn(
        idState,
        passwordState,
        queryString["search"]
      );
      if (
        response !== undefined &&
        response !== null &&
        response.code === 200
      ) {
        console.log(response.data.location);
        window.location.href = response.data.location;
      } else if (
        response !== undefined &&
        response !== null &&
        response.code !== 200
      ) {
        setPopupState(true);
        console.log(response);
      }
    } catch (e) {
      console.log(e);
    }
    setBackdropLoading(false);
  };

  const reactPopup = () => {
    return (
      <Popup onClose={() => setPopupState(false)} open={popupState}>
        <div
          onClick={() => setPopupState(false)}
          className={SmartSignInCss.PopupScreen}
        >
          <div
            onClick={(event) => event.stopPropagation()}
            className={SmartSignInCss.Popup}
          >
            <div className={SmartSignInCss.PopupTopArea}>
              <strong className={SmartSignInCss.PopupTopText}>
                로그인에 실패했습니다.
              </strong>
            </div>
            <div className={SmartSignInCss.PopupContentArea}>
              <p className={SmartSignInCss.PopupContentTitleText}>
                계정이 없거나 정보를 잘못 입력했습니다.
              </p>
              <p className={SmartSignInCss.PopupContentSubText}>
                통합회원으로 전환하지 않는 기존 회원은
              </p>
              <p className={SmartSignInCss.PopupContentSubText}>
                기존회원 탭으로 이동하여 로그인 해주세요.
              </p>
            </div>
            <div className={SmartSignInCss.PopupConfirmBtnArea}>
              <button
                onClick={() => setPopupState(false)}
                className={SmartSignInCss.PopupConfirmBtn}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };
  return (
    <div className={SmartSignInCss.BackgroundScreen}>
      <Backdrop className={classes.backdrop} open={backdropLoading}>
        <CircularProgress color={"primary"} />
      </Backdrop>
      {reactPopup()}
      <div className={SmartSignInCss.MainScreen}>
        <div className={SmartSignInCss.TopArea}>
          <img
            className={SmartSignInCss.MainLogo}
            alt={"smartMainLogo"}
            src={MainLogoImage}
          />
          <strong className={SmartSignInCss.TopAreaStrong}>
            나비엔 통합회원
          </strong>
        </div>
        <div className={SmartSignInCss.ContentArea}>
          <div className={SmartSignInCss.InputArea}>
            <label className={SmartSignInCss.IdLabel}>아이디</label>
            <div className={SmartSignInCss.InputDiv}>
              <div className={SmartSignInCss.InputLeftArea}>
                <img
                  className={SmartSignInCss.IdIcon}
                  alt={"IdIcon"}
                  src={IdIcon}
                />
                <input
                  type="text"
                  name="username"
                  id="username"
                  value={idState}
                  className={SmartSignInCss.Input}
                  placeholder="아이디를 입력해 주세요."
                  onChange={(event) => setIdState(event.target.value)}
                />
              </div>
              <div className={SmartSignInCss.InputRight}>
                {idResetBtnState && (
                  <button
                    type="reset"
                    className={SmartSignInCss.IdInputReset}
                    onClick={() => setIdState("")}
                  ></button>
                )}
              </div>
            </div>
          </div>
          <div className={SmartSignInCss.InputArea}>
            <label className={SmartSignInCss.IdLabel}>비밀번호</label>
            <div className={SmartSignInCss.InputDiv}>
              <div className={SmartSignInCss.InputLeftArea}>
                <img
                  className={SmartSignInCss.IdIcon}
                  alt={"PwIcon"}
                  src={PwIcon}
                />
                <input
                  type={passwordShowBtnState ? "text" : "password"}
                  name="password"
                  id="password"
                  value={passwordState}
                  className={SmartSignInCss.Input}
                  placeholder="비밀번호를 입력해 주세요."
                  onChange={(event) => setPasswordState(event.target.value)}
                />
              </div>
              <div className={SmartSignInCss.InputRight}>
                {passwordShowBtnState ? (
                  <button
                    type="button"
                    className={SmartSignInCss.PwInputOpen}
                    onClick={() =>
                      setPasswordShowBtnState(!passwordShowBtnState)
                    }
                  ></button>
                ) : (
                  <button
                    type="button"
                    className={SmartSignInCss.PwInputClose}
                    onClick={() =>
                      setPasswordShowBtnState(!passwordShowBtnState)
                    }
                  ></button>
                )}
              </div>
            </div>
          </div>
          <button
            className={SmartSignInCss.LoginBtn}
            onClick={loginButtonClick}
            disabled={!loginButtonState}
          >
            로그인
          </button>
        </div>
        <div className="container">
          <p>
            로그인하면 Google이 귀하의 기기를 제어할 수 있는 권한을 부여하는
            것입니다.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SmartSignInPre;
