import React from 'react';

const Error = () => {
  return (
        <div className="wrap-login100 p-t-50 p-b-90">
          <div className="flex-c w-full">
            <h1>400 - Bad Request</h1>
          </div>
          <div className="flex-c w-full">
            <p>If the problem continues, contac the site owner.</p>
          </div>
        </div> 
  );
};

export default Error;