import axios from 'axios';

let deply = "dev";
var naviauthApiInstance = null;

const hostname = window.location.hostname;

if (hostname.includes("dev")) {
  deply = "dev";
}
else if (hostname.includes("localhost")) {
  deply = "dev";
}
else if (hostname.includes("stg")) {
  deply = "stage";
}
else {
  deply = "prod";
}

if (deply === "dev") {
  naviauthApiInstance = axios.create({
    baseURL : 'https://krauth-dev-api.naviensmartcontrol.com'
  });
}
else if (deply === "stage") {
  naviauthApiInstance = axios.create({
    baseURL : 'https://krauth-stg-api.naviensmartcontrol.com'
  });
}
else if (deply === "prod") {
  naviauthApiInstance = axios.create({
    baseURL : 'https://krauth-api.naviensmartcontrol.com'
  });
}

export async function aironeUserSignIn(username, password, queryString) {
  var response = undefined;

  let data = JSON.stringify({
    "username" : username,
    "password" : password,
    "serviceType" : "airone"
  });
  let requUrl = `/api/v1/oauth/authorization` + queryString;
  let config = {
    headers : {
      "Content-Type" : "application/json"
    }
  };

  response = await naviauthApiInstance.post(requUrl, data, config);
  console.log('response is ', response)
  console.log('response data is ', response.data)
  return response.data;
}

export async function mateUserSignIn(username, password, queryString) {
  var response = undefined;
  console.log(typeof queryString)
  console.log( queryString)

  let data = JSON.stringify({
    "username" : username,
    "password" : password,
    "serviceType" : "mate"
  });
  let requUrl = `/api/v1/oauth/authorization` + queryString;
  let config = {
    headers : {
      "Content-Type" : "application/json"
    }
  };

  response = await naviauthApiInstance.post(requUrl, data, config);
  return response.data;
} 

export async function smartUserSignIn(username, password, queryString) {
  var response = undefined;
  console.log(typeof queryString)
  console.log( queryString)

  let data = JSON.stringify({
    "username" : username,
    "password" : password,
    "serviceType" : "naviensmart"
  });
  console.log('queryString', queryString)
  let requUrl = `/api/v1/oauth/authorization`+ queryString;
  let config = {
    headers : {
      "Content-Type" : "application/json"
    }
  };

  response = await naviauthApiInstance.post(requUrl, data, config);
  
  return response.data;
}